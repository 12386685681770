<template>
  <div id="Review" class="Review">
    <v-alert dense class="alert" :value="tipsInfo" :type="tipsInfoType">{{ tipsInfoContent }}</v-alert>
    <div class="top_content">
      <div class="left">
        <v-text-field
          v-model="projectName"
          dense
          height="32"
          prepend-icon="mdi-magnify"
          background-color="#E3ECF7"
          @keyup.enter.native="PostSearch()"
          rounded
          solo
          filled
          label="请输入项目名称"
        ></v-text-field>
      </div>
      <div class="left">
        <v-select
          v-model="reviewStatus"
          dense
          :items="selectTypeList"
          :item-text="(item) => `${item.name}`"
          :item-value="(item) => `${item.value}`"
          @change="PostSearch()"
          height="32"
          prepend-icon="mdi-magnify"
          background-color="#E3ECF7"
          rounded
          solo
          filled
          label="请选择审阅状态"
        ></v-select>
      </div>
    </div>
    <!-- 列表 -->
    <div class="tab_content">
      <div class="tab">
        <v-tabs v-model="tabflag" background-color="transparent" color="#3f81c1" @change="getFindReviewUserList">
          <v-tab v-for="(item, index) in items" :key="index">
            {{ item.name }}
          </v-tab>
        </v-tabs>
      </div>
      <v-tabs-items v-model="tabflag" class="my-4 tabs_items" v-if="cardList.length > 0">
        <v-tab-item v-for="(tabitem, index) in items" :key="index" class="tab_list">
          <div v-for="(iitem, index) in cardList" :key="index" class="card_item">
            <img
              v-if="iitem.project && iitem.project.image"
              class="card_item_img"
              :src="iitem.project && iitem.project.image"
            />
            <img v-else class="card_item_img" src="../../../assets/defaultImg.png" />
            <div class="card_item_content">
              <div class="content_title">
                标题：{{ iitem.project && iitem.project.projectName }}
              </div>
              <div class="content_text">账号：{{ iitem.applyUser && iitem.applyUser.userName }}</div>
              <div class="content_text">备注：{{ iitem.remark }}</div>
              <div class="content_info">
                <v-icon size="20" color="#333" style="margin-left:-2px">{{tabflag == 0?'mdi-account-edit':'mdi-account-tie'}}</v-icon>
                <div class="info_name" v-if="tabflag == 0"><span style="font-size:13px;">审阅人：</span>
                {{ iitem.reviewUser && iitem.reviewUser.userName }}</div>
                <div class="info_name" v-else><span style="font-size:13px;">提交人：</span>
                  {{ iitem.applyUser && iitem.applyUser.userName }}</div>
                <div class="info_time">{{ iitem.creationTime }}</div>
              </div>
            </div>
            <div v-if="tabflag == 1" @click="toProject(iitem, true)" class="content_btn3" :style="iitem.reviewStatus == 2 ? '' : 'color:#0C95FC'">
              审阅
            </div>
            <div
              v-if="tabflag == 0"
              class="content_btn content_btn2"
              @click="withdraw(iitem.reviewId)"
              :style="iitem.reviewStatus == 0 ? 'color:#EF5552' : ''"
            >
              撤回
            </div>
            <div v-if="tabflag == 0"  @click="iitem.reviewStatus == 2?toProject(iitem, false):''" class="content_btn" :style="iitem.reviewStatus == 2 ? 'color:#F5900C' : ''">
              查看
            </div>
            <div class="content_status" :style="'background:' + colorlist[iitem.reviewStatus]">
              {{ statusText[iitem.reviewStatus] }}
            </div>
          </div>
        </v-tab-item>
      </v-tabs-items>
      <div class="zanwu" v-if="cardList.length <= 0">
              <img src="../../../assets/NoData.png"/>
              <div>暂无数据</div>
            </div>
      <div class="pagination">
        <v-pagination v-model="pageNumber" :length="totalPages" :total-visible="7" @input="pageChange"></v-pagination>
      </div>
    </div>
    <!-- 弹框 -->
    <v-row justify="center">
      <!-- 删除二次确认框 -->
      <v-dialog v-model="ConfirmDialog" max-width="290">
        <v-card>
          <v-card-title class="text-h5"> {{ ConfirmTitle }} </v-card-title>
          <v-card-text>{{ ConfirmDetails }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey darken-2" text @click="ConfirmDialog = false"> 取消 </v-btn>
            <v-btn color="light-blue accent-3" text @click="deleteConfirm"> 确认 </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { getOid } from '@/utils/auth'
import { getFindReviewUser, deleteReviewUser } from '@/api/project'
import uploadFile from '../../../components/uploadFile.vue'
export default {
  components: {
    UploadFile: uploadFile,
  },
  data: () => ({
    tipsInfo: false,
    tipsInfoType: 'success',
    tipsInfoContent: '',
    //二次确认
    ConfirmDialog:false,
    ConfirmTitle:'',
    ConfirmDetails:'',
    deleteId:'',
    projectName: null,
    reviewStatus: null,
    selectedItem: '',
    selectTypeList: [
      { value: null, name: '全部' },
      { value: 0, name: '待审阅' },
      { value: 1, name: '审阅中' },
      { value: 2, name: '已审阅' },
    ],
    tabflag: 0,
    //tab列表
    items: [
      { name: '我的提交', value: 0 },
      { name: '我的审阅', value: 1 },
    ],
    page: 1,
    colorlist: { 0: '#FFAF46', 1: '#0C95FC', 2: '#44DB5E' },
    statusText: { 0: '待审阅', 1: '审阅中', 2: '已审阅' },
    //数据列表
    pageNumber: 1,
    pageSize: 12,
    totalPages: 1,
    cardList: [],
  }),
  created() {
    this.getFindReviewUserList()
  },
  watch: {
    projectName(val) {
      if (val == null || val == '') {
        this.getFindReviewUserList()
      }
    },
    reviewStatus(val) {
      if (val == null || val == '') {
        this.getFindReviewUserList()
      }
    },
  },
  methods: {
    toProject(item, review) {
      console.log(item)
      this.$router.push({
        path: `/triz/${item.project.projectId}`,
        query: {
          resume: item.resume,
          reviewId: item.reviewId,
          review
        },
      })
    },
    //撤回审核
    withdraw(id) {
      this.ConfirmDialog = true
        this.deleteId = id
        this.ConfirmTitle = '是否要撤回此项目？'
        this.ConfirmDetails = '撤回后需再次提交才可审核，请谨慎操作！'
      
    },
    deleteConfirm(){
deleteReviewUser(this.deleteId)
        .then((res) => {
          this.tipsInfo = true
          this.tipsInfoType = 'success'
          this.tipsInfoContent = '撤回成功'
          this.ConfirmDialog = false
          this.getFindReviewUserList()
          setTimeout(() => {
            this.tipsInfo = false
          }, 2000)
        })
        .catch(() => {
          this.tipsInfo = true
          this.tipsInfoType = 'error'
          this.tipsInfoContent = '撤回失败'
          this.ConfirmDialog = false
          setTimeout(() => {
            this.tipsInfo = false
          }, 2000)
        })
    },
    //查询事件
    PostSearch(e) {
      this.getFindReviewUserList()
    },
    //获取审阅列表
    getFindReviewUserList() {
      getFindReviewUser({
        flag: this.tabflag,
        projectName: this.projectName,
        reviewStatus: parseInt(this.reviewStatus),
        pageNumber: this.pageNumber - 1,
        pageSize: this.pageSize,
        organizationId: getOid(),
      })
        .then((res) => {
          this.cardList = res.data.content
          this.totalPages = res.data.totalPages
        })
        .catch(() => {})
    },
    //分页
    pageChange(e) {
      this.getFindReviewUserList()
    },
  },
}
</script>
<style lang="scss">
.Review {
  .top_content {
    display: flex;
    position: relative;
    .left {
      width: 240px;
      color: #999;
      .v-select__slot {
        position: relative;
        left: 12px;
      }
      .v-input {
        position: relative;
        left: -20px;
        top: 16px;
        .v-input__slot {
          box-shadow: none;
          .v-text-field__slot {
            position: relative;
            left: 15px;
            color: #999;
          }
        }
        .v-input__prepend-outer {
          position: relative;
          left: 45px;
          top: 2px;
          z-index: 1;
          .v-icon.v-icon {
            color: #3f81c1 !important;
          }
        }
      }
    }
  }
  .tab_content {
    margin-left: 16px;
    .pagination {
      position: absolute;
      right: 10px;
      bottom: 20px;
    }
    .tab {
      width: 300px;
    }
    .v-card__text,
    .v-card__title {
      padding: 8px 8px 0 8px;
    }
    .v-card__actions {
      padding: 0 8px;
    }
    .menu_list:hover {
      .v-list-item__title {
        color: #3f81c1;
      }
    }
    .tabs_items {
      width: calc(100% - 16px);
      .tab_list {
        position: relative;
        margin-bottom: 70px;
      }

      .card_item {
        background: #fff;
        position: relative;
        width: 100%;
        height: 132px;
        padding: 10px 0;
        border-bottom: 1px solid #f0f0f0;
        display: flex;
        .card_item_img {
          width: 215px;
          height: 112px;
          position: relative;
          border-radius: 6px;
        }
        .card_item_content {
          margin-left: 8px;
          width: calc(100% - 580px);
          height: 112px;
          align-items: center;
          .content_title {
            font-weight: bold;
            font-size: 14px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .content_text {
            font-size: 12px;
            margin-top: 4px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .content_info {
            position: absolute;
            bottom: 0;
            display: flex;
            font-size: 12px;
            height: 40px;
            line-height: 40px;
            .info_name {
              font-size: 14px;
              margin-left: 4px;
            }
            .info_time {
              font-size: 12px;
              margin-left: 24px;
              line-height: 44px;
            }
          }
        }
        .content_btn {
          width: 40px;
          margin: 0 100px 0 0;
          height: 25px;
          line-height: 25px;
          text-align: center;
          align-self: center;
          font-weight: bold;
          font-size: 14px;
          color: #c4c4c4;
          cursor: pointer;
        }
        .content_btn2 {
          margin: 0 0 0 100px;
          position: relative;
          left: 0px;
        }
        .content_btn3 {
          width: 40px;
          margin: 0 100px;
          height: 25px;
          line-height: 25px;
          text-align: center;
          align-self: center;
          font-weight: bold;
          font-size: 14px;
          color: #c4c4c4;
          cursor: pointer;
        }
        .content_status {
          position: absolute;
          right: 0;
          top: 10px;
          width: 80px;
          height: 40px;
          line-height: 40px;
          border-bottom-left-radius: 25px;
          background: #ffaf46;
          text-align: center;
          color: #fff;
          font-size: 14px;
        }
      }
      .text_title {
        line-height: 20px;
        font-weight: bold;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .text_content {
        font-size: 12px;
        color: #666;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        line-height: 20px;
      }
      .tabs_items_img {
        width: 136px;
        height: 106px;
        margin: 0 auto;
        .menu_list:hover {
          .v-list-item__title {
            color: #3f81c1;
          }
        }
      }
    }
  }
}
</style>